@import "styles/core.scss";
.background {
  @include flex-column;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-scrim-dark);
  z-index: 1003;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
}

.drawer {
  pointer-events: auto;
  @include flex-column;
  border-top-left-radius: var(--radius-lg);
  border-top-right-radius: var(--radius-lg);
  background: var(--color-background-primary);
  width: 100%;
  max-height: calc(100dvh - var(--space-300));
  overflow: hidden;

  margin-bottom: -100dvh;
  transition: margin-bottom 0.4s ease-in-out;

  &.open {
    margin-bottom: 0;
  }
}

.handleContainer {
  flex-shrink: 0;
  height: 32px;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.handle {
  width: 32px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--color-border-separator);
}

.contents {
  display: flex; // prevents content overflow
  overflow: hidden;
  flex: 1;
  padding: var(--space-200) var(--space-300);
}
