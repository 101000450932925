@import "styles/core.scss";
.buttonContainer {
  padding: 0;
}

.links {
  padding-left: 3px; // Aligning content with the expander button text
}

.addPaddingTop {
  @include page-width-sm-down {
    padding-top: var(--space-100);
  }
}

.addPaddingBottom {
  @include page-width-sm-down {
    padding-bottom: var(--space-200);
  }
}

.addPadding {
  padding-top: var(--space-50);

  @include page-width-sm-down {
    padding-top: var(--space-100);
  }
}
