@import "styles/core.scss";
.container {
  background-color: var(--color-background-primary);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: var(--z-index-modal);
  box-shadow: var(--shadow-100);

  &.isAnimating {
    &.isVisible {
      animation-direction: reverse;
    }
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.rightContainer {
  right: 0;
  top: 0;
  min-width: 321px;
  height: 100vh;

  &.insetContainer {
    top: var(--space-200);
    right: var(--space-200);
    height: calc(100vh - 32px);
  }

  &.isAnimating {
    animation: slide-in-right var(--duration-short) var(--timing-ease-out) forwards;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.leftContainer {
  left: 0;
  top: 0;
  min-width: 321px;
  height: 100vh;

  &.insetContainer {
    top: var(--space-200);
    left: var(--space-200);
    height: calc(100vh - 32px);
  }

  &.isAnimating {
    animation: slide-in-left var(--duration-short) var(--timing-ease-out) forwards;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.topContainer {
  left: 0;
  right: 0;
  top: 0;
  min-height: 200px;
  max-height: 100vh;

  &.insetContainer {
    top: var(--space-200);
    left: var(--space-200);
    right: var(--space-200);
  }

  &.isAnimating {
    animation: slide-in-top var(--duration-short) var(--timing-ease-out) forwards;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.bottomContainer {
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 200px;
  max-height: 100vh;

  &.insetContainer {
    bottom: var(--space-200);
    left: var(--space-200);
    right: var(--space-200);
  }

  &.isAnimating {
    animation: slide-in-bottom var(--duration-short) var(--timing-ease-out) forwards;
  }
}

.insetContainer {
  border-radius: var(--radius-md);
}

.header {
  @include heading-300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-300) var(--space-450) 0 var(--space-450);
}

.buttonContainer {
  @include flex-row;
  gap: var(--space-16);
  justify-content: right;
  padding: var(--space-200) var(--space-450);
  box-shadow: var(--shadow-250);

  button {
    overflow: hidden;
  }
}

.rightButtons {
  @include flex-row;
  gap: var(--space-8);
}

.children {
  padding: 0 var(--space-450);
  flex-shrink: 1;
  overflow-y: auto;
  margin-bottom: auto;
}
