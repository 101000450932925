@import "styles/core.scss";
.addPaddingTop {
  @include page-width-sm-down {
    padding-top: var(--space-100);
  }
}

.addPaddingBottom {
  @include page-width-sm-down {
    padding-bottom: var(--space-200);
  }
}

.addPadding {
  padding-top: var(--space-50);

  @include page-width-sm-down {
    padding-top: var(--space-100);
  }
}

.linkItem {
  min-height: 24px;
}
