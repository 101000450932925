@import "styles/core.scss";
.popup {
  @include removeDefaultButtonStyles;
  cursor: pointer;
  padding: var(--space-50);
  background: var(--color-background-primary);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-100);

  img {
    display: block;
    height: 120px;
    width: 120px;
    border-radius: inherit;
    object-fit: cover;
  }
}
