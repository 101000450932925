@import "styles/core.scss";
.editorContainer {
  width: 376px;
  overflow-y: auto;
  margin: 0 calc(-1 * var(--space-150));
  padding: var(--space-450) var(--space-150) 0 var(--space-150);
  position: relative;
}

.panelHeader {
  font-size: 24px;
}

.trailDetailsHeader {
  @include heading-300;
  margin-top: var(--space-600);
}

.groupIdLink,
.groupName {
  color: var(--color-text-secondary);
  margin-right: var(--space-100);
}

.detailsSectionWrapperContainer {
  margin: var(--space-150) 0 var(--space-50) 0;
  padding: var(--space-150) 0;
}

.detailsSectionWrapperHeader {
  display: flex;
  justify-content: space-between;
}

.detailsSectionEditIcon {
  display: none;
}

.detailsSectionWrapperWithLink {
  display: block;
  border-radius: var(--radius-lg);
  padding: 0 var(--space-150);
  margin: 0 calc(-1 * var(--space-150));

  &:hover {
    background-color: var(--color-neutral-50);
  }
}

.detailsSectionWrapperWithLink:hover .detailsSectionEditIcon {
  display: block;
}

.optionEditorTitle {
  @include heading-200;
  margin-bottom: var(--space-150);
}

.optionEditorTags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-100);
}

.optionEditorTag.optionEditorTag {
  background-color: var(--color-background-primary);
  padding: var(--space-300) var(--space-200);
}

.optionEditorEditableTag.optionEditorEditableTag {
  font-weight: bold;
}

.optionEditorSelectedTag.optionEditorSelectedTag {
  background-color: var(--color-background-primary-hover);
  border-color: var(--color-border-interactive-active);
}

.textInfo {
  @include text-200;
  display: flex;
  gap: var(--space-100);
  flex-wrap: wrap;
}

.textInfoLabel {
  font-weight: bold;
  flex-shrink: 0;
}

.confirmationModalText {
  @include text-200;
  padding-top: var(--space-300);
}

.discardButton {
  color: var(--color-text-error);
}

.textEditSectionWrapper {
  margin-bottom: var(--space-150);
}

.textEditSectionFooter {
  margin-top: var(--space-200);
  display: flex;
  justify-content: space-between;
}

.textEditSectionAccordion.textEditSectionAccordion {
  padding: var(--space-150) var(--space-300);
  background-color: var(--color-background-secondary);
  border-top-right-radius: var(--radius-lg);
  border-top-left-radius: var(--radius-lg);
}

.textEditSection {
  padding: var(--space-200) var(--space-300) var(--space-450) var(--space-300);
}

.textEditReadonlySection {
  @include text-100;
}

.textEditSectionReadonly {
  max-height: 440px;
  overflow-y: auto;
  margin-bottom: var(--space-300);
}

.textEditTranslationsLink {
  display: flex;
  gap: var(--space-100);
  align-items: center;
  font-weight: bold;
}

.adminNotesSectionWrapper {
  margin-top: var(--space-100);
  background-color: var(--color-blue-100);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-sticky);
  margin-left: calc(-1 * var(--space-450));
  margin-right: calc(-1 * var(--space-450));
}

.adminNotesSectionAccordion.adminNotesSectionAccordion {
  @include flex-column;
  padding: var(--space-200) var(--space-450);
  width: 390px;
}

.trigger {
  @include removeDefaultButtonStyles;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.headerContent {
  @include flex-row;
  align-items: flex-start;
  position: relative;
}

.icon {
  display: inline-block;
  padding-right: var(--space-100);
}

.slide.slide {
  padding-left: 20px; //showflake
  max-height: none;
}

.adminNoteContent {
  padding-top: var(--space-200);
  @include flex-column;
  align-items: flex-start;
  gap: var(--space-200);
}

.noteEditSection {
  padding-top: var(--space-450);
}
