@import "styles/core.scss";
// double selector needed to overwrite the Popover styles
.popover.popover {
  padding: 0;
}

.container {
  overflow-y: auto;
  padding: var(--space-50);
  min-width: 120px;
  max-width: 320px;

  @include flex-column;
}

.sectionOption {
  @include flex-column;
  gap: var(--space-100);
  padding: var(--space-100);
}

.sectionDivider {
  width: 100%;
  height: var(--borders-weight-sm);
  background-color: var(--color-border-separator);
}

.sectionHeader {
  @include heading-100;
  color: var(--color-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.option {
  @include removeDefaultButtonStyles;
  @include flex-row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--space-100);
  border-radius: var(--radius-md);
  text-align: left;
  outline: none;
  box-sizing: border-box;

  min-height: 36px;
  gap: var(--space-100);

  &:focus-visible {
    box-shadow: 0 0 0 var(--borders-weight-lg) var(--color-border-focus-default);
  }

  @include hover {
    background-color: var(--color-background-primary-hover);
  }

  svg,
  .iconBackground,
  .iconContainer {
    flex-shrink: 0;
  }
}

.text {
  @include flex-column;
  flex: 1;
  overflow: hidden;
}

.title,
.subtext {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.iconBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: var(--radius-md);
  background-color: var(--color-background-secondary);
}

.iconContainer {
  width: 20px;
  height: 20px;
}
