@import "styles/core.scss";
.title {
  text-align: center;
}

.container {
  @include flex-column;
  align-items: center;
  gap: var(--space-200);
}

.laurels {
  @include flex-row;
  justify-content: center;
  gap: var(--space-200);
}

.salesPitch {
  text-align: center;
  max-width: 75%; // Force text wrapping earlier than normal
}
