@import "styles/core.scss";
.container {
  @include flex-row;
  align-items: center;
  @include userSelectNone;
  cursor: pointer;
  // Required for the input which is absolutely positioned below. Even though
  // the input is hidden, positioning it absolutely without a relative ancestor
  // can shift the entire layout in some browsers. See XPLOR-1952
  position: relative;

  &.disabled,
  &.disabled input {
    cursor: default;
  }

  &.inputLast {
    justify-content: space-between;
  }

  &.top {
    align-items: flex-start;
  }

  &.sm {
    @include text-100;
    gap: var(--space-100);

    input,
    .checkbox {
      height: 16px;
      width: 16px;
      border-width: var(--borders-weight-sm);
    }
  }

  &.md {
    @include text-200;
    gap: var(--space-150);

    input,
    .checkbox {
      height: 24px;
      width: 24px;
      border-width: var(--borders-weight-md);
    }
  }

  &.disabled {
    color: var(--color-text-disabled);
  }

  input {
    position: absolute;
    opacity: 0;
    margin: 0;
    cursor: pointer;
  }

  input:focus-visible ~ .checkbox {
    box-shadow: 0 0 0 var(--borders-weight-md) var(--color-border-focus-default) inset;
  }

  input:checked:focus-visible ~ .checkbox,
  input:indeterminate:focus-visible ~ .checkbox {
    box-shadow: 0 0 0 var(--borders-weight-md) var(--color-border-focus-primary) inset;
  }

  input:checked ~ .checkbox,
  input:indeterminate ~ .checkbox {
    border-color: transparent;
  }

  input:disabled ~ .checkbox {
    background-color: var(--color-background-disabled);
  }

  input:checked:not(:disabled) ~ .checkbox,
  input:indeterminate:not(:disabled) ~ .checkbox {
    background-color: var(--color-interactive-primary);
  }

  @include hover {
    input:not(:checked):not(:indeterminate):not(:disabled) ~ .checkbox {
      border-color: var(--color-border-interactive-hover);
    }

    input:checked:not(:disabled) ~ .checkbox,
    input:indeterminate:not(:disabled) ~ .checkbox {
      background-color: var(--color-interactive-primary-hover);
    }
  }
}

.checkbox {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-style: solid;
  background-color: var(--color-background-primary);
  border-color: var(--color-border-interactive);
  border-radius: var(--radius-sm);
  transition: all 0.2s ease-out;

  svg {
    pointer-events: none; // Allow consumers to use the component onClick to stop propagation, etc.
    flex-shrink: 0;
  }
}

.checkboxContainer {
  @include flex-row;
  align-items: center;
  gap: var(--space-150);
}
