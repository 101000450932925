@import "styles/core.scss";
@keyframes fade-and-slide-in {
  0% {
    opacity: 0;
    transform: translateY(64px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// Copies the Modal fade and slide animation
.containerWithFadeIn {
  opacity: 0;
  transform: translateY(64px);
  animation: fade-and-slide-in 0.45s ease-out forwards;
}

.container {
  @include flex-column;
  position: fixed;
  z-index: 2147483639; // one higher than Osano banner and sign up modal
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-secondary);
}

.imageContainer {
  flex-grow: 1;
  position: relative;
}

.phone {
  max-height: 330px;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.texture {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.outerContainer {
  background: var(--color-background-primary);
  padding-bottom: var(--space-300);
  padding-top: var(--space-200);
}

.contentContainer {
  @include flex-column;
  padding: 0 var(--space-300);
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: var(--color-background-primary);

  img {
    height: 64px;
    width: auto;
  }
}

.header {
  padding: var(--space-200) 0;
}

.divider {
  height: 1px;
  background-color: var(--color-border-separator);
  margin: 0 auto;
  width: 100%;
}

.logoAndRatingContainer {
  display: flex;
  gap: var(--space-100);
  padding: var(--space-200);
}

.nameAndRatingContainer {
  @include flex-column;
  justify-content: center;
  align-items: flex-start;
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.close.close {
  position: absolute;
  right: var(--space-300);
  top: var(--space-300);
  z-index: var(--z-index-default);
}

.ratingContainer {
  display: flex;
  align-items: center;
  gap: var(--space-100);

  span {
    color: var(--color-text-secondary);
  }
}

.button {
  width: 100%;
}
