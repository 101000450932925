@import "styles/core.scss";
.type {
  display: flex;
  flex-direction: column;
  gap: var(--space-300);
}

.type:not(:only-child) {
  padding-top: var(--space-300);
  padding-bottom: var(--space-300);

  &:not(:first-child) {
    border-top: 1px solid var(--color-border-separator);
  }

  @include page-width-md-up {
    &:first-child {
      border-top: 1px solid var(--color-border-separator);
    }
  }
}

.title {
  font-weight: 700;
  margin-bottom: var(--space-50);
}

.description {
  @include text-100;
  color: var(--color-text-secondary);
  position: relative;
  word-wrap: break-word;
}

.clamp {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.inactive {
  color: var(--color-neutral-500);
}

.more {
  position: absolute;
  right: 0;
  bottom: 0;

  box-shadow: -9px -1px 7px 5px var(--color-background-primary);
}

.button {
  @include removeDefaultButtonStyles;

  @include text-100;

  text-decoration: underline;
  font-weight: 700;

  cursor: pointer;

  background: var(--color-background-primary);
}

.content {
  @include page-width-md-up {
    padding-top: var(--space-300);
  }
}

.adminAlert {
  position: relative;
  padding: var(--space-100);
  overflow: visible;
}

.editIcon.editIcon {
  position: absolute;
  left: 98%;
  top: -20%;
  visibility: hidden;
}

.adminAlert:hover {
  border-radius: var(--radius-md);
  border: var(--borders-weight-md) solid var(--color-border-separator);
}

.adminAlert:hover .editIcon {
  visibility: visible;
}

.adminDetails {
  @include text-50;
  align-items: center;
  color: var(--color-text-secondary);
  margin-top: var(--space-100);
  @include flex-row;
}

.separator {
  margin: 0 6px;
  background-color: var(--color-border-separator);
  height: 8px;
  width: 1px;
  display: inline-block;
}

.linkToTranslations {
  margin-top: var(--space-100);
}
