@import "styles/core.scss";
@mixin overlap($amount) {
  .avatar,
  .overflowAvatar,
  .overflowCTA {
    &:not(:first-child) {
      margin-left: -$amount;
    }
  }
}

.overflowCTA {
  z-index: 1;
}

.container {
  @include removeDefaultButtonStyles;
  margin: 0; // Needed to remove default margin on some browsers when an anchor tag is rendered
  display: inline-flex;

  &.xs {
    @include overlap(var(--space-50));

    .overflowAvatar {
      @include text-100;
      // Snowflake
      font-size: 8px;
    }
  }

  &.sm {
    @include overlap(var(--space-50));

    .overflowAvatar {
      @include text-100;
    }
  }

  &.md {
    @include overlap(var(--space-100));

    .overflowAvatar {
      @include text-200;
    }
  }

  &.xs,
  &.sm,
  &.md {
    .overflowAvatar {
      color: var(--color-text-primary-inverse);
    }
  }
}

a.container,
button.container {
  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:focus-visible {
    border-radius: var(--radius-sm);
    box-shadow: 0 0 0 var(--borders-weight-lg) var(--color-border-focus-default);
  }

  @include hover {
    border-radius: var(--radius-sm);
    box-shadow: 0 0 0 var(--borders-weight-lg) var(--color-border-focus-default);
  }
}

.overflowAvatar {
  background-color: var(--color-scrim-dark);
  white-space: nowrap;
  text-overflow: clip;
}

a.overflowAvatar,
button.overflowAvatar {
  &:focus-visible {
    box-shadow: 0 0 0 var(--borders-weight-lg) var(--color-border-focus-default); // Matches the normal Avatar focus styling
  }

  @include hover {
    text-decoration: underline;
  }
}

.avatar,
.overflowAvatar,
.overflowCTA {
  flex-shrink: 0;
}
