@import "styles/core.scss";
.container {
  @include flex-column;
  width: 100%;
  max-width: 768px;
  margin: 72px auto 0px;
  text-align: center;

  &.noTopMargin {
    margin-top: 0;
  }
}

.illustration {
  margin-bottom: var(--space-300);
}

.title {
  @include heading-400;
  margin-bottom: var(--space-100);
}

.description {
  @include text-200;
  color: var(--color-text-secondary);
}

.inverted {
  color: var(--color-text-primary-inverse);
}

.buttons {
  @include flex-column;
  gap: var(--space-200);
  margin: var(--space-300) auto 0;
  width: max-content;
}
