@import "styles/core.scss";
.customAvatar {
  @include removeDefaultButtonStyles;
  margin: 0; // Needed to remove default margin on some browsers when an anchor tag is rendered
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-round);
  transition: all 0.2s ease-out;

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.bordered {
  border: var(--borders-weight-md) solid var(--color-background-primary);

  &.xs,
  &.sm {
    border-width: var(--borders-weight-sm);
  }
}

.xs {
  height: 16px;
  width: 16px;
}

.sm {
  height: 36px;
  width: 36px;
}

.md {
  height: 48px;
  width: 48px;
}

.lg {
  height: 80px;
  width: 80px;
}

.xl {
  height: 128px;
  width: 128px;
}

@mixin insetContent {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--radius-round);
}

a.userAvatar,
button.userAvatar {
  &:focus-visible {
    .imageWrapper::after {
      @include insetContent;
      box-shadow: inset 0 0 0 var(--borders-weight-lg) var(--color-border-focus-default);
    }
  }

  @include hover {
    .imageWrapper::after {
      @include insetContent;
      background-color: var(--color-scrim-light);
    }
  }
}

.avatarPhoto {
  height: 100%;
  width: 100%;
  background: var(--color-neutral-200);
  border-radius: var(--radius-round);
  object-fit: cover;
  object-position: center;
}

.fill {
  height: 100%;
  width: 100%;
}

.plusBadge {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
