@import "styles/core.scss";
.container {
  @include flex-row;
  flex-wrap: nowrap;
  width: 327px;
  min-height: 90px;
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: var(--color-background-primary);
  box-shadow: var(--shadow-200);
}

.imageContainer {
  position: relative;
  width: 80px;
  flex-shrink: 0;
}

.image {
  object-fit: cover;
}

.iconFallback {
  @include flex-row;
  background-color: var(--color-background-secondary);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.info {
  @include flex-column;
  flex-wrap: nowrap;
  padding: var(--space-150) var(--space-200);
}

.name {
  @include text-200-bold;
}

.state {
  @include text-100;
  color: var(--color-text-secondary);
  margin-bottom: 2px;
}

.details {
  @include text-100;
  color: var(--color-text-secondary);
}

.adminActions {
  position: absolute;
  top: 0;
  right: var(--space-50);
}
