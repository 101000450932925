@import "styles/core.scss";
.container.container {
  @include flex-column;
  gap: var(--space-200);

  div {
    min-width: 0;
  }

  label,
  .text,
  .subtext {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.labelElement {
  @include flex-column;
  flex: 1;

  &.sm {
    .text {
      line-height: 16px; // Match height of the input itself
    }

    .text,
    .subtext {
      @include text-100;
    }

    .subtext {
      color: var(--color-text-secondary);
    }
  }

  &.md {
    .text {
      @include text-200;
      line-height: 24px; // Match height of the input itself
    }

    .subtext {
      @include text-100;
      color: var(--color-text-secondary);
    }
  }

  &.disabled {
    &.sm,
    &.md {
      .text,
      .subtext {
        color: var(--color-text-disabled);
      }
    }
  }
}
