@import "styles/core.scss";
.textArea {
  @include text-200;
  resize: none;
  box-sizing: border-box;
  width: 100%;
  padding: var(--space-150) var(--space-200);
  border-radius: var(--radius-md);
  background-color: var(--color-background-primary);
  outline: none !important;
  border: none !important;
  // Use box-shadow instead of border to avoid the text jumping when the border width changes on focus
  box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive);

  @include placeholderColor(var(--color-text-placeholder));

  &.resizable {
    resize: vertical;
  }

  &:disabled {
    background-color: var(--color-background-disabled);
    box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-disabled);
    color: var(--color-text-disabled);
    @include placeholderColor(var(--color-text-disabled));
  }

  &.hasError:not(:disabled) {
    box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-error);
  }

  &:focus-visible:not(.hasError) {
    box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-interactive-active);
  }

  &:not(:focus-visible):not(:disabled):not(.hasError) {
    @include hover {
      box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive-hover);
    }
  }
}
