@import "styles/core.scss";
.content {
  @include flex-column;
  gap: var(--space-200);
}

.dates {
  @include flex-row;
  gap: var(--space-100);
}

.datePicker {
  width: 204px;
}

.addToParkPage {
  @include flex-column;

  .area {
    @include text-100;
    color: var(--color-text-secondary);
  }
}

.toast {
  width: 327px;
}

.trail {
  padding-bottom: var(--space-200);
}

.separator {
  border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
}

.linkToTranslations {
  text-align: right;
  display: block;
}
