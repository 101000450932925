@import "styles/core.scss";
.title {
  margin-bottom: var(--space-300);
}

.collapsible {
  overflow: hidden;
  max-height: 100vh; // hack: explicitly define max-height to a large static value for transitions support
  transition: max-height 300ms ease-in-out;
}

.collapsed {
  max-height: 64px;

  @include page-width-sm-down {
    max-height: 104px;
  }
}

.collapseToggle {
  margin-top: var(--space-300);
}
