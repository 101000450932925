@import "styles/core.scss";
.modalContent {
  padding-top: var(--space-200);
}

.editedModalContent {
  min-height: 360px;
}

.confirmationModalContent {
  min-height: 542px;
}
