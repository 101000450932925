@import "styles/core.scss";
@import '../layouts/layoutStyles.module.scss';

.container {
  @include colLayoutDouble;
  scroll-margin-top: 112px;

  @include maxWidth($grid-page-max-width) {
    @include colLayout;
  }

  @include page-width-md-down {
    @include flex-column;
    flex-wrap: nowrap;
    gap: var(--space-600);
  }

  @include page-width-sm-down {
    scroll-margin-top: calc(var(--space-600) + 64px /* sticky nav height */);
  }
}

.overview {
  @include flex-column;
  width: 100%;
  height: min-content;
  position: sticky;
  top: calc(var(--space-800) * 2);
  @include colCount(3);

  @include maxWidth($grid-page-max-width) {
    max-width: 276px;
    @include colCount(2);
  }

  @include page-width-md-down {
    position: static;
    @include flex-column;
    max-width: unset;
    gap: var(--space-100);
    flex-wrap: wrap;
  }
}

.ugcContainer {
  width: 100%;
  @include colCount(8);

  @include maxWidth($grid-page-max-width) {
    @include colCount(4);
  }

  @include page-width-md-down {
    border-top: var(--borders-weight-sm) solid var(--color-border-separator);
  }

  @include page-width-lg-up {
    margin-top: calc(-1 * var(--space-450));
  }
}

.desktopSpacer {
  @include maxWidth($grid-page-max-width) {
    display: none;
  }
}

.reviewTitle {
  @include heading-400;
  margin-bottom: 0;
  margin-top: 0;

  @include page-width-lg-up {
    @include heading-600;
  }
}

.secondaryText {
  color: var(--color-text-secondary);
}

.reviewUser {
  @include flex-row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.rating {
  margin-bottom: 0;
  margin-top: var(--space-200);
}

.ratingTags {
  margin-top: var(--space-150);
  display: flex;
  gap: var(--space-100);
  flex-wrap: wrap;
}

.summaryExclusion {
  margin-bottom: var(--space-100);
}

.badgeContainer {
  @include flex-row;
  @include text-200-bold;
  gap: var(--space-100);
  color: var(--color-blue-700);
  align-items: center;
}

.reviewBody {
  margin-bottom: 0;
  margin-top: var(--space-150);
}

.reviewBodyFirst {
  background-color: var(--color-background-secondary);
  padding: var(--space-200);
  border-radius: var(--radius-md);

  .reviewText {
    margin-bottom: 0;
  }
}

.reviewText {
  @include paragraph-200;
  color: var(--color-text-secondary);
  margin-top: 0;
  margin-bottom: 0;
}

.attributes {
  margin-top: var(--space-200);
}

.attributeText {
  color: var(--color-text-secondary);
}

.translationButton {
  margin-top: var(--space-100);
  margin-bottom: 0;

  button {
    @include text-100-bold;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.newPhotoCarousel {
  margin-bottom: 0;
  margin-top: var(--space-300);
}

.readMoreLink {
  @include text-200-bold;
  margin-top: var(--space-300);
  @include flex-row;
  gap: var(--space-100);
  align-items: center;
}

.visitorStatsContainer {
  @include flex-row;
  margin-top: var(--space-450);
  align-items: center;
  gap: var(--space-100);

  @include page-width-md-down {
    margin-top: var(--space-300);
  }
}

.visitorStats {
  @include flex-column;
}

.visitorsCount {
  @include text-100;
}

.busiestMonth {
  @include text-100;
  font-size: var(--font-size-50);
  color: var(--color-text-secondary);
}
