@import "styles/core.scss";
// https://www.figma.com/file/9Y1n3VoMJAcTGPe82bvySj/Denali-Components?node-id=8814%3A7611&mode=dev

@mixin buttonBorder() {
  &::after {
    pointer-events: none;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--radius-md);
    @content;
  }
}

.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--color-text-primary);

  &.truncate {
    overflow: hidden;

    .button:first-of-type {
      overflow: hidden;

      .textContent {
        overflow: hidden;

        span:first-of-type {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &:not(.selected) {
    @include buttonBorder() {
      border: 1px solid var(--color-border-interactive);
    }
  }

  &:not(.selected):has(button:focus-visible) {
    @include buttonBorder() {
      border: var(--borders-weight-lg) solid var(--color-border-focus-default);
    }
  }

  &.selected:not(:has(button:focus-visible)) {
    @include buttonBorder() {
      border: 1.5px solid var(--color-border-interactive-active); // Snowflake width
    }
  }

  &.selected:has(.button:first-of-type:focus) {
    @include buttonBorder() {
      border: 4px solid var(--color-neutral-600); // Snowflake width
    }
  }
}

.container {
  .button {
    @include hover {
      background-color: #f4f4f1; // Snowflake
    }
  }

  &.selected {
    .button {
      background-color: #f4f4f1; // Snowflake
    }
  }
}

.button {
  @include removeDefaultButtonStyles;
  @include flex-row;
  align-items: center;
  justify-content: center;
  gap: var(--space-8);
  outline: none;
  color: var(--color-text-primary);
  background-color: var(--color-background-primary);
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: var(--radius-md);
    border-bottom-left-radius: var(--radius-md);
    padding-left: var(--padding-left);
  }

  &:last-of-type {
    border-top-right-radius: var(--radius-md);
    border-bottom-right-radius: var(--radius-md);
    padding-right: var(--padding-right);

    &:not(:first-of-type) {
      padding-left: var(--padding-right);

      &:focus {
        position: relative;

        @include buttonBorder() {
          border: 4px solid var(--color-neutral-600); // Snowflake width
        }
      }
    }
  }

  &.noChevron:first-of-type:last-of-type {
    padding-left: var(--padding-left);
    padding-right: var(--padding-left);
  }

  svg {
    flex-shrink: 0;
  }
}

.sm {
  --padding-left: var(--space-12);
  --padding-right: var(--space-8);
  height: 36px;

  .textContent {
    @include text-100;
  }
}

.md {
  --padding-left: var(--space-16);
  --padding-right: var(--space-12);
  --gap: var(--space-12);
  height: 48px;

  .textContent {
    @include text-200;
  }
}

.textContent {
  @include flex-row;
  align-items: center;
  justify-content: center;
  gap: var(--space-8);
  white-space: nowrap;
}
