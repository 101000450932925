@import "styles/core.scss";
.container {
  background-color: var(--color-background-accent-weak);
  @include flex-row;
  justify-content: center;
  transition: padding 0.2s ease;

  @include page-width-sm-down {
    padding: var(--space-40) var(--space-300);
    @include flex-column;
  }
}

.mobileContent {
  display: none;

  @include page-width-sm-down {
    display: block;
  }
}

.content {
  width: 100%;
  max-width: 1920px;
  display: none;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: var(--space-300);

  @include page-width-md-down {
    padding: var(--space-800) var(--space-600);
  }

  @include page-width-lg-up {
    padding: var(--space-800) 80px;
  }

  &.desktopContent {
    @include page-width-md-up {
      display: grid;
    }
  }

  &.botContent {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.section {
  grid-column: span 1;
}

.title {
  margin-top: 0;
  margin-bottom: var(--space-100);
  @include heading-200;

  @include page-width-xs {
    @include text-200;
  }
}

.hiddenTitle {
  visibility: hidden;
}

.listItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}
