@import "styles/core.scss";
.container {
  @include flex-column;
  gap: var(--space-300);
  padding: var(--space-300) 0;
  width: 342px;
  background-color: var(--color-background-primary);
  box-shadow: var(--shadow-100);
  border-radius: var(--radius-lg);

  &.fixed {
    position: absolute;
    top: var(--space-300);
    right: calc(var(--space-600) + 48px);
  }

  .closeButton {
    position: absolute;
    top: var(--space-200);
    right: var(--space-200);
  }
}

.boundariesHeader {
  text-align: center;
  padding: var(--space-100);

  .backButton {
    position: absolute;
    top: var(--space-200);
    left: var(--space-200);
  }
}

.trailFilterCard {
  @include flex-column;
  gap: var(--space-300);
  padding: 0 var(--space-300);

  .title {
    padding-right: var(--space-600);
  }

  .userInfo {
    @include flex-row;
    gap: var(--space-100);
  }
  .actions {
    @include flex-row;
    gap: var(--space-150);
    justify-content: flex-end;
  }
}

.geoBoundaryListItem {
  padding: var(--space-200) var(--space-300);
  cursor: pointer;
  &:hover {
    background-color: var(--color-background-primary-hover);
  }
}
