@import "styles/core.scss";
.popper {
  display: flex; // prevents content overflow
  overflow: hidden;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-200);
  background: var(--color-background-primary);
  max-width: 640px;
  max-height: 420px;
  box-sizing: border-box;
  padding: var(--space-300);
  z-index: var(--z-index-popover);

  &:not(.isVisible) {
    opacity: 0;
  }

  &.isVisible {
    opacity: 1;
  }

  &.isAnimating {
    &.top {
      animation: animate-in-top 0.2s ease-in-out forwards;
    }

    &.bottom {
      animation: animate-in-bottom 0.2s ease-in-out forwards;
    }

    &.isVisible {
      animation-direction: reverse;

      // pointer-events none, along with useGroupHover, is needed to avoid an issue where hovering in between the anchor and the popover can cause
      // the popover to repeatedly open and close since onMouseEnter is triggered as the popover animates out.
      pointer-events: none;
    }
  }
}

@keyframes animate-in-bottom {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes animate-in-top {
  0% {
    opacity: 0;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
