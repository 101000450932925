@import "styles/core.scss";
.mapContainer {
  height: 100%;
  border-radius: var(--radius-xl);
  overflow: hidden;

  @include page-width-md-down {
    width: 100%;
    aspect-ratio: 1.333;
  }
}

.shimmerContainer {
  background-color: rgba(76, 64, 26, 0.08);
  height: 100%;
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.exploreMore.exploreMore {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--z-index-default);

  &:not(.loading):not(.disabled):active {
    transform: scale(0.96) translateX(-50%); // maintain existing scale behavior on click
  }
}

.mapRendererContainer {
  height: 100%;
  position: relative;
}

.mobileClickableMap {
  position: absolute;
  width: 100%;
  cursor: pointer;
  z-index: var(--z-index-default);
  aspect-ratio: 1.333;
}
