@import "styles/core.scss";
.container {
  @include flex-column;
  gap: var(--space-50);
}

.errorText {
  @include flex-row;
  justify-content: space-between;
  @include text-100;
  color: var(--color-text-error);
}

.helperText {
  @include text-100;
  color: var(--color-text-secondary);
}
