@import "styles/core.scss";
.container {
  position: relative;
  height: 56px; // Height on the container so it can be overridden by the className prop if needed

  &:not(.hideBottomBorder) {
    border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
  }
}

.tabBar {
  position: relative;
  @include flex-row;
  gap: 32px;
  height: inherit;
  width: 100%;
  @include userSelectNone;

  &.start {
    justify-content: flex-start;
    overflow-x: auto;
    @include removeScrollBar;
  }

  &.full {
    .tab {
      flex: 1;
      overflow: hidden;
    }
  }
}

.tab {
  @include removeDefaultButtonStyles;
  margin: 0; // Needed to remove default margin on some browsers when an anchor tag is rendered
  height: inherit;
  @include flex-row;
  justify-content: center;
  align-items: center;
  gap: var(--space-100);
  border-bottom: var(--borders-weight-md) solid transparent;
  cursor: pointer;
  @include text-200-bold;
  color: var(--color-text-secondary);
  transition: all 0.2s ease-out;

  &.active {
    color: var(--color-text-primary);

    @include hover {
      color: var(--color-text-primary-hover);
    }
  }

  &:not(.active) {
    @include hover {
      color: var(--color-text-secondary-hover);
      border-bottom-color: var(--color-border-interactive);
    }
  }

  &.topIndicator {
    border-top: var(--borders-weight-md) solid transparent;
    border-bottom: unset;
    &:not(.active) {
      @include hover {
        color: var(--color-text-secondary-hover);
        border-top-color: var(--color-border-interactive);
      }
    }
  }

  &:focus-visible {
    text-decoration: underline;
  }

  &:focus,
  &:focus-visible {
    outline: none !important;
  }

  svg {
    flex-shrink: 0;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.activeTabIndicator {
  position: absolute;
  left: 0; // Updated in the tsx
  bottom: 0;
  width: 0px; // Updated in the tsx
  height: var(--borders-weight-md);
  background-color: var(--color-border-interactive-active);
  transition: unset; // If this changes, the tsx must be updated. We only want the transition to be set after a delay
}

.topIndicator {
  top: 0;
}

.leftArrow,
.rightArrow {
  @include removeDefaultButtonStyles;
  margin: 0;
  position: absolute;
  top: 0;
  height: 100%;
  cursor: pointer;
  align-items: center;
  display: none; // Toggled with 'display: flex' in the tsx
}

.leftArrow {
  left: 0;
  padding-right: var(--space-100);
  background: linear-gradient(90deg, var(--color-background-primary) 66%, rgba(255, 255, 255, 0) 100%);
}

.rightArrow {
  right: 0;
  padding-left: var(--space-100);
  background: linear-gradient(270deg, var(--color-background-primary) 66%, rgba(255, 255, 255, 0) 100%);
}
