@import "styles/core.scss";
// Look at the type definition for ClassNames from react-day-picker to see where these are applied

.root {
  @include userSelectNone;
}

.multiple_months {
  .month:last-of-type {
    .nav {
      // When we are rendering multiple months, the final month only gets a "next month" arrow button, but we still want to render it on the right
      flex-direction: row-reverse;
    }
  }
}

.months {
  @include flex-row;
  gap: var(--space-200);
}

.month {
  flex: 1;
}

.caption {
  @include flex-column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 36px;

  &:has(.nav) {
    padding: 0 36px; // Leave space for the nav buttons
  }
}

.caption_dropdowns {
  @include flex-row;
  justify-content: center;
  gap: var(--space-50);
  width: 100%;
}

.caption_label {
  @include heading-100;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown_month,
.dropdown_year {
  position: relative;
  overflow: hidden;

  select:focus-visible + .caption_label {
    text-decoration: underline;
    text-underline-offset: var(--space-50);
  }
}

.dropdown_year {
  flex-shrink: 0;
}

.nav {
  @include flex-row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

// Mimic flat IconButton
.nav_button {
  @include removeDefaultButtonStyles;
  height: 36px;
  width: 36px;
  color: var(--color-text-primary);
  border-radius: var(--radius-round);
  outline-color: var(--color-border-focus-default);
  transition: all var(--duration-short) var(--timing-ease-out);

  &:disabled {
    color: var(--color-text-disabled);
  }

  &:not(:disabled) {
    cursor: pointer;

    &:active {
      transform: scale(0.96);
      background-color: var(--color-background-primary-hover);
    }

    @include hover {
      background-color: var(--color-background-primary-hover);
    }
  }
}

.table {
  position: relative;
  width: 100%;
  border: none;
  border-spacing: 0;
}

.head_cell,
.cell {
  width: calc(100% / 7);
}

.head_cell {
  @include text-100;
  height: 36px;
  padding: 2px;
}

.cell {
  padding: 0;
}

.day {
  @include removeDefaultButtonStyles;
  @include text-100;
  color: transparent; // We display the visible text using the after pseudo-element so that it can stay centered as we make the horizontal padding asymmetrical
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-md);
  outline-color: var(--color-border-focus-default);
  background-color: var(--color-background-primary);
  background-clip: content-box;
  transition: all var(--duration-short) var(--timing-ease-out);

  &:not(.day_disabled) {
    cursor: pointer;
    &:not(.day_selected) {
      @include hover {
        background-color: var(--color-background-primary-hover);
      }
    }
  }

  &::after {
    position: absolute;
    content: attr(data-date);
    width: 100%;
    left: 0;
    color: var(--color-text-primary);
  }
}

.day_disabled {
  &::after {
    color: var(--color-text-disabled);
  }
}

.day_selected {
  @include text-100-bold;
  background-color: var(--color-background-inverse);

  &::after {
    color: var(--color-text-primary-inverse);
  }
}

.day_range_start {
  &:not(.day_range_end) {
    padding-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.day_range_middle {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;

  &:not(.day_selected) {
    background-color: var(--color-background-primary-hover);
  }
}

.day_range_end {
  &:not(.day_range_start) {
    padding-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
