@import "styles/core.scss";
.container {
  @include flex-row;
  align-items: center;

  &.left {
    flex-direction: row-reverse;
  }

  &.sm {
    @include text-100;
    gap: 2px;

    &.complex {
      @include text-100-bold;
      gap: 2px;
    }

    .stars {
      gap: 2px;
    }
  }

  &.md {
    @include text-200;
    gap: var(--space-50);

    &.complex {
      @include text-200-bold;
      gap: var(--space-100);
    }

    .stars {
      gap: var(--space-50);
    }
  }

  &.lg {
    @include text-300;
    gap: var(--space-50);

    &.complex {
      @include text-300-bold;
      gap: var(--space-100);
    }

    .stars {
      gap: var(--space-100);
    }
  }
}

.stars {
  @include flex-row;
  align-items: center;
}
