@import "styles/core.scss";
.inputContainer {
  @include flex-row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--color-background-primary);
  border-radius: var(--radius-md);
  padding-left: var(--space-200);
  padding-right: var(--space-200);
  // Use box-shadow instead of border to avoid the text jumping when the border width changes on focus
  box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive);

  &.disabled {
    background-color: var(--color-background-disabled);
    box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-disabled);
  }

  &:not(:focus-within):not(.disabled):not(.hasError) {
    @include hover {
      box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive-hover);
    }
  }

  &.hasError:not(.disabled) {
    box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-error);
  }

  &:focus-within:not(.hasError) {
    box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-interactive-active);
  }

  &.md {
    height: 48px;
  }

  &.sm {
    height: 36px;
  }

  svg {
    flex-shrink: 0;
  }
}

.input {
  flex: 1;
  @include text-200;
  border: none;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;

  @include placeholderColor(var(--color-text-placeholder));

  &:disabled {
    color: var(--color-text-disabled);
    @include placeholderColor(var(--color-text-disabled));
  }

  // Use these margins instead of flex gap because lastpass inserts a div next to input elements which can cause overflow issues
  &.hasLeadingIcon {
    margin-left: var(--space-150);
  }

  &.md {
    @include text-200;
  }

  &.sm {
    @include text-100;
  }

  &.hasTrailingIcon {
    margin-right: var(--space-150);
  }

  &:focus-visible {
    outline: none;
  }
}
