@import "styles/core.scss";
.container {
  @include flex-row;
  gap: var(--space-150);
  align-items: center;

  * {
    flex-shrink: 0;
  }
}

.textContent {
  @include flex-column;
  flex: 1 1;
  overflow: hidden;
}

.label,
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label {
  @include text-200;
}

.description {
  @include text-100;
  color: var(--color-text-secondary);
}
