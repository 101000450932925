@import "styles/core.scss";
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalBackground {
  // Extra background-color so that when a user is flipping between photos, we maintain a darker lightbox
  background-color: var(--color-background-primary-inverse-overlay);
}

.navigationButtonLeft {
  padding-left: var(--space-300);
  position: absolute;
  top: 50%;
  left: 0;
  z-index: var(--z-index-popover);
}

.navigationButtonRight {
  padding-right: var(--space-300);
  position: absolute;
  top: 50%;
  right: 0;
  z-index: var(--z-index-popover);
}

.hidden {
  visibility: hidden;
}

.blockMenuItem.blockMenuItem.blockMenuItem {
  @include removeDefaultButtonStyles;
  @include text-100;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;
  height: 40px;
  border-radius: var(--radius-md);

  span {
    padding: 0 var(--space-100);
  }

  &:hover {
    border-radius: var(--radius-md);
    background-color: var(--color-background-primary-hover);
  }
}

.adminItem.adminItem {
  display: flex;
  align-items: center;
  @include text-100;
  color: var(--color-text-secondary);
  padding: var(--space-100);
  display: flex;
  text-align: left;
  width: 100%;
  border: 0;
  background: var(--color-background-primary);
  justify-content: flex-start;
}

.closeButton.closeButton {
  display: none;
}
