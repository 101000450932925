@import "styles/core.scss";
.container {
  @include flex-column;
  gap: var(--space-200);
}

.privacy {
  margin: 0; // overwrite negative margin that the component has by default
}

.errorMessage {
  @include text-100;
  color: var(--color-text-error);
}
