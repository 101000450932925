@import "styles/core.scss";
.container {
  display: flex;
  gap: var(--space-300);
  flex-direction: column-reverse;

  @include page-width-lg-up {
    flex-direction: row;
    gap: var(--space-600);
  }
}

.trailList {
  width: 100%;
}

.mapContainer {
  width: 100%;

  @include page-width-lg-up {
    height: calc(100vh - 120px);
    position: sticky;
    top: var(--space-600);
  }
}

.map {
  aspect-ratio: 1.333;
  overflow: hidden;
  border-radius: var(--radius-lg);

  @include page-width-lg-up {
    height: 100%;
    aspect-ratio: unset;
    border-radius: var(--radius-xl);
  }
}

.exploreMoreMobileButton.exploreMoreMobileButton {
  display: flex;
  margin-top: var(--space-450);

  @include page-width-lg-up {
    display: none;
  }
}

.desktopOnly {
  display: none;

  @include page-width-lg-up {
    display: block;
  }
}

.mobileOnly {
  display: block;

  @include page-width-lg-up {
    display: none;
  }
}
