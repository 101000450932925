@import "styles/core.scss";
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  @include text-100;
  color: var(--color-text-secondary);
  padding: 0px var(--space-100);
  white-space: nowrap;
}

.line {
  height: 50%;
  border-top: 1px solid var(--color-border-separator);
  width: 100%;
}
