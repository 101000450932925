@import "styles/core.scss";
.stickyWrapper {
  height: 100%;
  overflow-y: scroll;
}

.sort {
  background: var(--color-background-primary);
  margin-bottom: var(--space-200);
  padding-bottom: var(--space-32);
  border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
}
