@import "styles/core.scss";
.container {
  @include removeDefaultButtonStyles;
  padding: var(--space-150) 0px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border-top: var(--borders-weight-sm) solid var(--color-border-separator);

  &:focus-visible {
    .shareImageContainer {
      box-shadow: 0 0 0 var(--borders-weight-lg) var(--color-border-focus-default);
    }
  }

  &:last-child {
    border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
  }
}

.shareImageContainer {
  @include flex-row;
  width: 48px;
  height: 48px;
  background: var(--color-background-secondary);
  border-radius: var(--radius-round);
  align-items: center;
  justify-content: space-around;
  margin-right: var(--space-150);
}

.shareDrawer {
  @include flex-row;
  align-items: center;
}

.title {
  @include text-200;
  margin-right: auto;
}
