@import "styles/core.scss";
@mixin activeOrHover {
  &:not(.loading):not(.disabled) {
    &:active {
      @content;
    }

    @include hover {
      @content;
    }
  }
}

.header {
  background: linear-gradient(180deg, var(--color-neutral-black) 30.21%, rgba(0, 0, 0, 0) 100%);
  left: 0;
  right: 0;
  top: 0;

  @include page-width-sm-down {
    padding: var(--space-300) var(--space-200);

    .overflow {
      display: none;
    }

    .trailName {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 200px;
    }
  }
}

.userInfo,
.iconsContainer {
  @include flex-row;
  align-items: center;
}

.userContainer {
  @include flex-column;
  padding-left: var(--space-150);

  .userName {
    @include flex-row;
    align-items: center;
    @include text-200;
    color: var(--color-text-primary-inverse);
    padding-right: var(--space-50);
  }

  .userDetails,
  .secondaryText {
    @include flex-row;
    align-items: center;
  }

  .timestamp,
  .trailName {
    @include text-100;
    color: var(--color-text-primary-inverse);
  }

  .dividerDot {
    color: var(--color-text-primary-inverse);
  }
}

.header,
.footer {
  position: absolute;
  @include flex-row;
  padding: var(--space-300);
  align-items: center;
  z-index: 2;

  &.userInfo + .iconsContainer {
    justify-content: space-between;
  }

  &:not(.userInfo + .iconsContainer) .iconsContainer {
    margin-left: auto;
  }
}

.footer {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--color-neutral-black) 77.6%);
  left: 0;
  right: 0;
  bottom: 0;

  .title {
    @include text-100;
    color: var(--color-text-primary-inverse);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
  }

  @include page-width-sm-down {
    padding: var(--space-300) var(--space-200);
  }
}

// snowflake, this icon button has different color than the one in our design system
// https://github.com/vercel/next.js/issues/65480
.iconButton.iconButton {
  color: var(--color-neutral-white);
  background-color: transparent;

  @include activeOrHover {
    color: var(--color-text-primary);
    border-color: var(--color-background-primary);
    background-color: var(--color-background-primary);
  }
}
