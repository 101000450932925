@import "styles/core.scss";
.detailsContainer {
  @include flex-column;
  gap: 32px;
}

.detailsSection {
  @include flex-column;
  gap: var(--space-200);
}

.datePicker.datePicker {
  width: 100%;
}
