@import "styles/core.scss";
@mixin carouselPin($color) {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: $color;
  color: var(--color-text-primary-inverse);
  border-radius: 32px;
  // var(--borders-weight-md) causes a display bug vs 2px
  border: 2px solid var(--color-background-primary);
  filter: drop-shadow(0px 4px 8px rgba(66, 82, 110, 0.2));

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -7px;
    right: calc(50% - 8px);
    z-index: 2;
    width: 0;
    height: 0;
    overflow: hidden;
    border: solid 8px transparent;
    border-bottom: 0;
    border-top-color: $color;
    filter: drop-shadow(0px 4px 8px rgba(66, 82, 110, 0.2)); // TODO https://alltrails.atlassian.net/browse/XPLOR-2043
  }

  &::before {
    bottom: -10px;
    z-index: 1;
    border-top-color: var(--color-background-primary);
  }
}

// This provides us a space at the bottom of the trailhead pin to avoid triggering mouse leave
// events in TrailheadMarker followed immediately by mouseEnter events on Markers:
.trailheadMarker {
  background-color: transparent;
  padding-top: 6px;
  padding-bottom: 20px;
}

.trailCarouselPin {
  @include carouselPin(var(--color-text-primary));
}

.activityCarouselPin {
  @include carouselPin(var(--color-blue-900)); // snowflake
}

.trackIconButton {
  // snowflake
  border-color: var(--color-blue-700) !important;
  background-color: var(--color-blue-700) !important;

  &:not(.loading):not(.disabled) {
    &:active {
      border-color: var(--color-blue-900) !important;
      background-color: var(--color-blue-900) !important;
    }

    @include hover {
      border-color: var(--color-blue-900) !important;
      background-color: var(--color-blue-900) !important;
    }
  }
}

.text {
  @include text-100-bold;
  color: var(--color-text-primary-inverse);
  min-width: 48px;
  text-align: center;
}
