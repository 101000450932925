@import "styles/core.scss";
.copyHTMLButton {
  float: right;
}

.customizeButton {
  margin-bottom: var(--space-50);
  margin-right: var(--space-200);
}

.checkboxHolder {
  height: 32px;
  display: flex;
  align-items: center;
}

.previewTitle {
  margin: 0;
  padding-bottom: var(--space-200);
  @include heading-300;
  text-align: center;
}

.preview {
  padding: var(--space-300);
  height: 472px;

  @include maxWidth(767px) {
    height: 340px;
  }

  background-color: var(--color-background-secondary);
  border-radius: var(--radius-lg);

  iframe {
    width: 100%;
    height: calc(100% - 40px);
  }
}

.hidden {
  display: none;
}

.customizeForm {
  min-height: 48px;
  margin: var(--space-200) 0;
}
